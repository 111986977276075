import { Browser } from 'js/shared/Browser.js';
import { json_to_urlencoded } from 'js/shared/form-helpers.js';
const { v4: uuidv4 } = require('uuid');

// let events = [];
// let page_session_id = uuidv4();

// let stop = rrweb.record({
//     emit(event) {
//         events.push(event);
//     },
//     maskAllInputs: true,
// });

// function save() {
//     if (events.length) {
//         let data = {
//             page: new URL(window.location).pathname,
//             events: events,
//             page_session_id: page_session_id,
//         };
//         events = [];
//         fetch('/public/record_screen.php', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'text/json',
//             },
//             body: JSON.stringify(data),
//         });
//     }
// }

// addEventListener('pagehide', save);

// setInterval(save, 10 * 1000);

// const vendor = ((navigator && navigator.vendor) || '').toLowerCase();
// const user_agent = ((navigator && navigator.userAgent) || '').toLowerCase();

// window.onerror = function (msg, _path, line, column, error) {
//     let data = {
//         error: error ? error.stack : '',
//         source: _path,
//         column: column,
//         line: line,
//         msg: msg,
//         ua: user_agent,
//         version: (navigator && navigator.appVersion) || '',
//         vendor: vendor,
//         browser: Browser.getBrowserName(),
//         page: location.href,
//     };
//     fetch('/api/form/get-csrf')
//         .then((res) => {
//             return res.json();
//         })
//         .then((csrf) => {
//             fetch('/api/js-error', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded',
//                     'X-Csrf-Token': csrf.token_value,
//                 },
//                 body: json_to_urlencoded(data),
//             });
//             return false;
//         });
// };

// document.addEventListener('click', (e) => {
//     const data = {
//         window_width: window.innerWidth,
//         window_height: window.innerHeight,
//         x: e.layerX,
//         y: e.layerY,
//         page: new URL(location.href).pathname,
//     };
//     if (e.target.href && e.target.href !== '#') {
//         e.preventDefault();
//     }
//     fetch('/public/record_heatmap.php', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: json_to_urlencoded(data),
//     }).then((_) => {
//         if (e.target.href && e.target.href !== '#') {
//             window.location = e.target.href;
//         }
//     });
//     return false;
// });
