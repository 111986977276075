import { h, render, Fragment } from 'preact';

export default function Content() {
    return (
        <div className="relative bg-[#fff8f6] font-faust">
            <div className="mx-auto text-center max-w-md pt-20 pb-8 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div>
                    <h2 className="text-4xl font-semibold tracking-wider text-[#381b13]">
                        Why Lucent Cash Shines
                    </h2>
                    <p className="mt-5 text-lg text-[#381b13]">
                        When you need extra funds, Lucent Cash is your number one choice for <span className="text-[#dd612e]">transparency and convenience</span>.
                    </p>
                    <p className="text-lg text-[#381b13]">With our support, you can focus on <span className="text-[#dd612e]">returning to the things you love</span> without the added worry.</p>
                </div>
                <div className="flex justify-center items-center">
                    <img className="h-[auto] lg:h-[35rem]" src="/public/src/img/romance_1.jpg" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true" />
                </div>
            </div>
        </div>
    );
}
