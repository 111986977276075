import { h, render, Fragment } from "preact";
import Hero from "js/components/design/home/Hero.js";
import Featured from "js/components/design/home/Featured.js";
import Application from "js/components/design/home/Borrow.js";
import Content from "js/components/design/home/Content.js";
import CTA from "js/components/design/home/CTA.js";
import About from "js/components/design/home/About.js";

export default function Home() {
    return (
        <Fragment>
            <Hero />
            <About />
            <Featured />
            <Application />
            <Content />
            <CTA />
        </Fragment>
    );
}
