import { h, render, Fragment } from "preact";

export default function About() {
  return (
    <div className="bg-[#faf8f9] font-faust relative">
      {/* <div className="w-30 h-30 p-10 rounded-full bg-white" /> */}
      {/* Header */}
      <div className="relative bg-[#faf1df]">
        {/* <div className="absolute inset-0 bg-[]" /> */}
        <div className="flex flex-col justify-center items-center relative mx-auto max-w-7xl px-6 py-24 sm:pt-[5rem] lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-[#381b13] md:text-5xl lg:text-6xl">From Us, To You</h1>
          <p className="mt-6 max-w-3xl text-xl text-[#381b13] text-justify">
            Seize the day with Lucent Cash, your trusted partner in flexible installment loans. We're committed to empowering individuals through transparent, personalized service and competitive rates.
          </p>
          <p className="mt-6 max-w-3xl text-xl text-[#381b13] text-justify">With our simplified application and convenient payment terms, Lucent Cash provides ready access to funds for your unexpected expenses. Borrow confidently from Lucent Cash and see a clearer financial future today!</p>
          <div className="flex justify-center items-center">
            <img className="hidden lg:block h-[15rem] lg:h-[15rem] 2xl:h-[20rem] 3xl:h-[25rem]" src="/public/src/img/pet_1.png" />
            <img className="h-[15rem] lg:h-[15rem] 2xl:h-[20rem] 3xl:h-[25rem]" src="/public/src/img/breakfast_1.jpg" />
            <img className="hidden lg:block h-[15rem] lg:h-[15rem] 2xl:h-[20rem] 3xl:h-[25rem]" src="/public/src/img/elder_1.png" />
          </div>
        </div>
      </div>
      {/* Overlapping cards */}
      <section className="relative z-10 mx-auto -mt-32 max-w-7xl px-6 lg:px-8" aria-labelledby="contact-heading">
        <div className="grid grid-cols-1 gap-y-4 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-0">
          <div className="flex flex-col rounded-2xl bg-white shadow-xl">
            <div className="relative flex-1 px-6 pt-[2rem] md:px-8">
              <div className="flex flex-col justify-center items-center">
                <h3 className="text-2xl font-semibold text-[#381b13]">Convenient</h3>
                <p className="mt-4 text-base text-[#381b13]">Apply <span className="text-[#dd612e]">online anytime</span>, anywhere you have internet access.</p>
              </div>
              <div className="flex justify-center items-center">
                <img
                  className="h-[10rem] lg:h-[12rem] w-22 inline-flex items-center justify-center"
                  src="/public/src/img/apply_5.png"
                  alt="Quick Application"
                  data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true"
                />
              </div>
            </div>
            <div className="flex justify-center items-center rounded-bl-2xl rounded-br-2xl bg-white px-6 pb-8 pt-2 md:px-8">
              <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-[#dd612e] hover:bg-orange-600 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: #f29f57 0px 10px 40px -5px;">
                Get Started
              </a>
            </div>
          </div>
          <div className="flex flex-col rounded-2xl bg-white shadow-xl">
            <div className="relative flex-1 px-6 pt-[2rem] md:px-8">
              <div className="flex flex-col justify-center items-center">
                <h3 className="text-2xl font-semibold text-[#381b13]">Secure</h3>
                <p className="mt-4 text-base text-[#381b13]">Trust Lucent Cash to keep your <span className="text-[#dd612e]">information protected</span>.</p>
              </div>
              <div className="flex justify-center items-center">
                <img
                  className="h-[11rem] lg:h-[13rem] w-22 inline-flex items-center justify-center"
                  src="/public/src/img/security_5.png"
                  alt="Safe Payments"
                  data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true"
                />
              </div>
            </div>
            <div className="flex justify-center items-center rounded-bl-2xl rounded-br-2xl bg-white px-6 pb-8 pt-2 md:px-8">
              <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-[#dd612e] hover:bg-orange-600 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: #f29f57 0px 10px 40px -5px;">
                Get Started
              </a>
            </div>
          </div>
          <div className="flex flex-col rounded-2xl bg-white shadow-xl">
            <div className="relative flex-1 px-6 pt-[2rem] md:px-8">
              <div className="flex flex-col justify-center items-center">
                <h3 className="text-2xl font-semibold text-[#381b13]">Friendly</h3>
                <p className="mt-4 text-base text-[#381b13]">Our <span className="text-[#dd612e]">friendly and welcoming</span> Customer Care Team is here for you.</p>
              </div>
              <div className="flex justify-center items-center">
                <img
                  className="h-[10rem] lg:h-[12rem] w-22 inline-flex items-center justify-center"
                  src="/public/src/img/support_5.png"
                  alt="Support"
                  data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true"
                />
              </div>
            </div>
            <div className="flex justify-center items-center rounded-bl-2xl rounded-br-2xl bg-white px-6 pb-8 pt-2 md:px-8">
              <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-[#dd612e] hover:bg-orange-600 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: #f29f57 0px 10px 40px -5px;">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
