import { h, render, Fragment } from 'preact';

export default function Featured() {
    return (
        <div className="relative bg-[#faf8f9] pb-16 pt-[5rem] font-faust">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div>
                    <h2 className="text-4xl font-semibold tracking-wider text-[#381b13]">
                        Making Life Easier with Easy Borrowing
                    </h2>
                    <p className="mt-5 text-lg text-[#381b13]">
                        Lucent Cash simplifies the borrowing process, putting <span className="text-[#dd612e]">straight-forward</span> installment loans <span className="text-[#dd612e]">easily within reach</span>. Get done and get back to what matters!
                    </p>
                </div>
                <div className="mt-8">
                    <img className="h-[15rem] mx-auto mb-[3rem] md:h-[35rem] 3xl:h-[40rem]" src="/public/src/img/freedom_1.jpg" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true" />
                    <a
                        href="/apply-now"
                        className="transition-colors duration-300 ease-in-out text-white bg-[#dd612e] hover:bg-orange-600 no-underline font-inter text-center px-[3rem] py-[1rem] rounded-md shadow-sm text-lg font-medium"
                        style="box-shadow: #f29f57 0px 10px 40px -5px;"
                    >
                        Get Started
                    </a>
                </div>
            </div>
        </div>
    );
}
