import { h, render, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { json_to_urlencoded } from '@xmlleads/form-magic';
import PrivateRoute from 'js/components/User/PrivateRoute.js';
import loadable from '@loadable/component';
import MainLayout from 'js/components/design/MainLayout.js';
import AuthProvider from 'js/components/User/AuthProvider.js';
import 'js/shared/logging.js';
import HomePage from 'js/pages/Home.js';
const React = require('react');
const ReactDOM = require('react-dom');

const Routes = () => {
    // TODO Add "loading" fallback to loadables
    /**
     * {fallback: <Loading />}
     */

    const Loading = () => {
        useEffect(() => {
            const disable = (e) => {
                e.preventDefault();
            };
            const body = document.querySelector('body');
            body.addEventListener('touchmove', disable);
            body.classList.add('overflow-hidden');
            return () => {
                body.removeEventListener('touchmove', disable);
                body.classList.remove('overflow-hidden');
            };
        }, []);
        return (
            <div className="absolute left-0 right-0 top-0 bottom-0 z-50 bg-gray-100 flex  items-center content-center justify-center justify-items-center">
                <div className="text-5xl">Loading...</div>
            </div>
        );
    };

    const c = {
        fallback: <Loading />,
    };

    // load pages
    const ApplyNow = loadable(() => import('js/pages/ApplyNow.js'), c);
    const Rejected = loadable(() => import('js/pages/ApplyNow/Rejected.js'), c);
    const FAQPage = loadable(() => import('js/pages/FAQ.js'), c);
    const RatesAndTerms = loadable(
        () => import('js/pages/RatesAndTerms.js'),
        c
    );
    const TermsOfUse = loadable(() => import('js/pages/TermsOfUse.js'), c);
    const DataCollection = loadable(
        () => import('js/pages/DataCollection.js'),
        c
    );
    const SameDayFunding = loadable(
        () => import('js/pages/SameDayFunding.js'),
        c
    );
    const ContactUs = loadable(() => import('js/pages/ContactUs.js'), c);
    const Login = loadable(() => import('js/pages/Login.js'), c);
    const Account = loadable(() => import('js/pages/Account.js'), c);
    // const LoanSigned = loadable(() => import('js/pages/LoanSigned.js'), c);
    const Military = loadable(() => import('js/pages/Military.js'), c);
    const Denied = loadable(() => import('js/pages/Denied.js'), c);
    const BankVerification = loadable(
        () => import('js/pages/BankVerification.js'),
        c
    );
    const Reset = loadable(() => import('js/pages/Reset.js'), c);
    const UpdatePassword = loadable(
        () => import('js/pages/UpdatePassword.js'),
        c
    );
    const ViewAgreement = loadable(
        () => import('js/pages/Account/ViewAgreement.js'),
        c
    );
    const Offer = loadable(() => import('js/pages/Offer.js'), c);

    return (
        <Switch>
            <Route exact path="/">
                <MainLayout>
                    <HomePage />
                </MainLayout>
            </Route>
            <Route path="/faq">
                <MainLayout>
                    <FAQPage />
                </MainLayout>
            </Route>
            <Route path="/loading">
                <MainLayout>
                    <Loading />
                </MainLayout>
            </Route>
            <Route path="/apply-now" exact>
                <ApplyNow />
            </Route>
            <Route path="/apply-now/rejected">
                <MainLayout>
                    <Rejected />
                </MainLayout>
            </Route>
            <Route path="/rates-and-terms">
                <MainLayout>
                    <RatesAndTerms />
                </MainLayout>
            </Route>
            <Route path="/terms-of-use">
                <MainLayout>
                    <TermsOfUse />
                </MainLayout>
            </Route>
            <Route path="/data-collection">
                <MainLayout>
                    <DataCollection />
                </MainLayout>
            </Route>
            <Route path="/same-day-funding">
                <MainLayout>
                    <SameDayFunding />
                </MainLayout>
            </Route>
            <Route path="/contact-us">
                <MainLayout>
                    <ContactUs />
                </MainLayout>
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/reset">
                <Reset />
            </Route>
            <PrivateRoute path="/updatepassword">
                <MainLayout>
                    <UpdatePassword />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute path="/account" exact>
                <MainLayout>
                    <Account />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute path="/account/loans">
                <MainLayout>
                    <Account showLoanOverView={ true } />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute path="/account/military">
                <MainLayout>
                    <Military />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute path="/account/denied">
                <MainLayout>
                    <Denied />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute path="/account/view-agreement/:loan_id">
                <ViewAgreement />
            </PrivateRoute>
            <PrivateRoute path="/bank-verification" exact>
                <MainLayout>
                    <BankVerification />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute path="/offer">
                <Offer />
            </PrivateRoute>
            <Route path="*">
                <h1>404 - Not Found</h1>
            </Route>
        </Switch>
    );
};

export default Routes;
