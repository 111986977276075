import { h, render, Fragment } from "preact";
// #00915C
export default function Steps() {
    return (
        <ol role="list" className="overflow-hidden font-inter">
            <li className="relative pb-10">
                <div className="-ml-px absolute mt-0.5 top-4 left-5 w-0.5 h-full bg-[#dd612e]" aria-hidden="true" />
                {/* <!-- Complete Step --> */}
                <div className="relative flex items-start group">
                    <span className="h-12 flex items-center">
                        <span className="relative z-10 w-10 h-10 flex items-center justify-center bg-[#dd612e] rounded-full">
                            {/* <!-- Heroicon name: solid/check --> */}
                            <svg
                                className="w-7 h-7 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </span>
                    <span className="ml-4 mt-1 min-w-0 flex flex-col">
                        <span className="text-sm font-semibold text-[#dd612e] tracking-wide uppercase">
                            Apply Easily and Securely
                        </span>
                        <span className="text-sm text-[#381b13]">
                            Fill out our secure online application in just a few short steps.
                        </span>
                    </span>
                </div>
            </li>

            <li className="relative pb-10">
            <div className="-ml-px absolute mt-0.5 top-4 left-5 w-0.5 h-full bg-[#dd612e]" aria-hidden="true" />
                {/* <!-- Complete Step --> */}
                <div className="relative flex items-start group">
                    <span className="h-12 flex items-center">
                        <span className="relative z-10 w-10 h-10 flex items-center justify-center bg-[#dd612e] rounded-full">
                            {/* <!-- Heroicon name: solid/check --> */}
                            <svg
                                className="w-7 h-7 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </span>
                    <span className="ml-4 mt-1 min-w-0 flex flex-col">
                        <span className="text-sm font-semibold text-[#dd612e] tracking-wide uppercase">
                            Trust Through Visibility
                        </span>
                        <span className="text-sm text-[#381b13]">
                            Login and confirm your personal information so we know we're sending funds to the right place.
                        </span>
                    </span>
                </div>
            </li>

            <li className="relative pb-10">
            <div className="-ml-px absolute mt-0.5 top-4 left-5 w-0.5 h-full bg-[#dd612e]" aria-hidden="true" />
                {/* <!-- Complete Step --> */}
                <div className="relative flex items-start group">
                    <span className="h-12 flex items-center">
                        <span className="relative z-10 w-10 h-10 flex items-center justify-center bg-[#dd612e] rounded-full">
                            {/* <!-- Heroicon name: solid/check --> */}
                            <svg
                                className="w-7 h-7 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </span>
                    <span className="ml-4 mt-1 min-w-0 flex flex-col">
                        <span className="text-sm font-semibold text-[#dd612e] tracking-wide uppercase">
                            Sign your loan agreement
                        </span>
                        <span className="text-sm text-[#381b13]">
                            Review your new loan agreement before signing online.
                        </span>
                    </span>
                </div>
            </li>

            <li className="relative">
                <div className="relative flex items-start group">
                    <span className="h-12 flex items-center">
                        <span className="relative z-10 w-10 h-10 flex items-center justify-center bg-[#dd612e] rounded-full">
                            {/* <!-- Heroicon name: solid/check --> */}
                            <svg
                                className="w-7 h-7 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </span>
                    <span className="ml-4 mt-1 min-w-0 flex flex-col">
                        <span className="text-sm font-semibold text-[#dd612e] tracking-wide uppercase">
                            Receive your funds
                        </span>
                        <span className="text-sm text-[#381b13]">
                            If approved, funds will be deposited into your checking account as soon as the next business day.
                        </span>
                    </span>
                </div>
            </li>
        </ol>
    );
}
