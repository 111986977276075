import { h, render, Fragment } from "preact";

export default function CTA() {
  return (
    <div className="relative bg-[#fffffd] font-faust">
      <div className="mx-auto text-center max-w-md py-20 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div>
          <h2 className="text-4xl font-semibold tracking-wider text-[#381b13]">
            Ready to start?
          </h2>
          <p className="mt-5 text-lg text-[#381b13]">
            Click below to get started and let Lucent Cash <span className="text-[#dd612e]">cover you brilliantly</span>!
          </p>
        </div>
        <div>
          <div className="my-[2rem] flex justify-center items-center">
            <img className="hidden lg:block h-[auto] lg:h-[15rem] 2xl:h-[25rem]" src="/public/src/img/mother_2.jpg" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true" />
            <img className="h-[auto] lg:h-[15rem] 2xl:h-[25rem]" src="/public/src/img/elder_2.jpg" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true" />
            <img className="hidden lg:block h-[auto] lg:h-[15rem] 2xl:h-[25rem]" src="/public/src/img/father_2.jpg" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear" data-aos-once="true" />
          </div>
          <a
            href="/apply-now"
            className="transition-colors duration-300 ease-in-out text-white bg-[#dd612e] hover:bg-orange-600 no-underline font-inter text-center px-[3rem] py-[1rem] rounded-md shadow-sm text-lg font-medium"
            style="box-shadow: #f29f57 0px 10px 40px -5px;"
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
}