import { h, render, Fragment } from "preact";
import { useEffect } from "preact/hooks";
import Header from "js/components/design/Header.js";
import "css/pages/hero.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Hero() {
    useEffect(() => {
        Aos.init({ delay: 200, once: false });
    }, []);

    return (
        <div id="hero" className="flex justify-center items-start relative bg-cover bg-center w-full h-[100vh] xs:h-[100vh] sm:h-[100vh] lg:h-[90vh] bg-[#fbd8a0] sm:bg-cover sm:bg-center lg:bg-cover lg:bg-center">
            <main className="lg:relative font-faust" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear">
                <div className="flex justify-center items-center mx-auto max-w-7xl w-full pt-16 pb-10 text-center md:mt-[2rem] lg:pt-35 lg:pb-33">
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-4xl text-center tracking-tight font-extrabold text-[#381b13] sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                            The Clear Choice For Your Financial Needs
                        </h1>
                        <p className="mt-8 max-w-md mx-auto text-xl text-center text-[#381b13] mt-[1rem] sm:text-xl md:text-2xl md:max-w-3xl">
                            Apply anytime. Transparent terms. Financial clarity.
                        </p>
                        <a
                            href="/apply-now"
                            className="transition-colors duration-300 ease-in-out text-white bg-[#dd612e] hover:bg-orange-600 mt-[1rem] no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium"
                            style="box-shadow: #f29f57 0px 10px 40px -5px;"
                        >
                            Apply Now
                        </a>
                    </div>
                </div>
            </main>
        </div>
    );
}
